/* eslint-disable @shopify/jsx-no-complex-expressions */
/* eslint-disable @shopify/jsx-no-hardcoded-content */
import React from 'react';
import PropTypes from 'prop-types';
import SocBar from './socBar';
import { isReadingZero } from '../helpers/isReadingZero';

const StringCell = React.memo(function BmuCell({
  string,
  soc,
  voltage,
  current,
  onlineStatus
}) {
  return (
    <div
      className='string-cell'
      style={{
        // marginTop: '5vh',
        top: `${(string - 1) * 60}px`
      }}
    >
      <div style={{ position: 'relative', top: '-47px' }}>
        <SocBar
          column_name='A'
          soc={soc ? soc.toFixed(3) : 0.0}
          onlineStatus={onlineStatus ? 'online' : 'offline'}
          height={10}
        />
      </div>
      <div
        className={`string-voltage ${onlineStatus ? 'online' : 'offline'} ${
          isReadingZero(voltage ? voltage.toFixed(3) : 0.0) ? 'grayText' : ''
        }`}
      >
        {voltage ? voltage.toFixed(3) : 0.0} V
      </div>
      <div
        className={`string-current ${onlineStatus ? 'online' : 'offline'} ${
          isReadingZero(current ? current.toFixed(3) : 0.0) ? 'grayText' : ''
        }`}
      >
        {current ? current.toFixed(3) : 0.0} A
      </div>
    </div>
  );
});

StringCell.propTypes = {
  string: PropTypes.number,
  soc: PropTypes.number,
  voltage: PropTypes.number,
  current: PropTypes.number,
  onlineStatus: PropTypes.string
};

export default StringCell;
