import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  meterReadingsEventName,
  subscribe,
  unsubscribe
} from '../helpers/events.js';
import StringRack from '../ess/stringRack.js';
import StringCell from '../ess/stringCell.js';
import { isOnline } from '../helpers/isOnline.js';
import { getScale } from '../helpers/getScale';

let STRINGS = [1, 2, 3, 4, 5, 6, 7, 8];

export const DefaultBatteryView = ({ viewProps, containerSize }) => {
  const selectedUlids = viewProps.selectedUlidsState?.[0];
  const [readings, setReadings] = useState({});
  const ulid = selectedUlids?.[0];

  useEffect(() => {
    subscribe(meterReadingsEventName, (e) =>
      setReadings(e.detail?.[ulid] || {})
    );
    return () => unsubscribe(meterReadingsEventName);
  }, [ulid]);

  const getSoc = (string) => {
    return readings['string_' + string + '_soc'];
  };
  const getVoltage = (string) => {
    return readings['string_' + string + '_voltage'];
  };
  const getCurrent = (string) => {
    return readings['string_' + string + '_current'];
  };
  const getOnline = () => {
    return 'read_at' in readings
      ? isOnline(parseInt(readings.read_at) * 1000)
      : true;
  };

  return (
    <div
      style={{
        width: '100%',
        height: '100%'
      }}
    >
      <div className='flex-container' style={{ height: '100%' }}>
        <div
          style={{
            transformOrigin: 'center',
            transform: `scale(${getScale(
              { width: 420, height: 485 },
              containerSize
            )})`
          }}
        >
          <StringRack />
          {STRINGS.map((num) => {
            return (
              <StringCell
                string={num}
                soc={getSoc(num)}
                voltage={getVoltage(num)}
                current={getCurrent(num)}
                onlineStatus={getOnline()}
                key={num}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

DefaultBatteryView.propTypes = {
  viewProps: PropTypes.object,
  containerSize: PropTypes.object
};
