/* eslint-disable @shopify/jsx-no-hardcoded-content */
import React from 'react';

let STRINGS = [1, 2, 3, 4, 5, 6, 7, 8];
// eslint-disable-next-line no-empty-pattern
export default React.memo(function StringRack({}) {
  return (
    <div className='string-rack'>
      <div className='string-rack-table-layout-fixed'>
        {STRINGS.map((num) => {
          return (
            <div className='string-rack-row' key={num}>
              <div className='string-rack-slot' key={num % 2}>
                <span className='string-rack-slot-label'>String {num}</span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
});
